<template>
  <div>
    <sidebar-home-two :sidebar="sidebar" @toggleSidebar="toggleSidebar"  :menuItems="navs" />
    <header-product @toggleSidebar="toggleSidebar" :menuItems="navs"/>
    <TandA-Title-Area/>
      
    <section class="appie-service-details-area pt-100 pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                   <body-nav-sidebar/>
                </div>
                <div class="col-lg-8">
                    <div class="service-details-content">
                      
                        <div class="content">
                            <h3 class="title">Refund Policy</h3>
                            <span>
                                Thank you for choosing our Roftr Clouds LLP software. We are committed to providing you with the best possible experience. 
                                Please read our refund policy carefully to understand your rights and responsibilities regarding refunds.
                            </span>
                            <br />                            
                            <h5>1. General Refund Terms</h5><br>
                            <p>
                                <ul class="custom_bullet">
                                    <li>Refunds are applicable only for subscriptions purchased directly from our official website.</li>
                                    <li>Refund requests must be submitted within 14 days of the initial purchase date.</li>
                                    <li>Refunds are not applicable to renewal payments, as users are notified in advance of the renewal date.</li>
                                </ul>
                            </p>
                            <br />
                            <h5>2. Eligibility for Refund</h5> <br>You may be eligible for a refund under the following circumstances:<br>
                            <p>
                                <ul class="custom_bullet">
                                    <li>The software does not perform as described or fails to meet its stated functionality.</li>
                                    <li>You encounter unresolved technical issues that prevent proper use of the software despite our support team’s efforts to resolve them.</li>
                                    <li>Accidental duplicate purchases made on the same account.</li>
                                </ul>
                            </p>
                            <br />
                            <h5>3. Non-Refundable Conditions</h5> <br> Refunds will not be issued in the following cases:<br>
                            <p>
                                <ul class="custom_bullet">
                                    <li>Failure to cancel the subscription before the renewal date.</li>
                                    <li>Non-usage or dissatisfaction without valid technical reasons.</li>
                                    <li>Violations of our Terms of Service.</li>
                                </ul>
                            </p>
                            <br />
                            <h5>4. Refund Process</h5><br>
                            <p>
                                <ul class="custom_bullet">
                                    <li>Submit your refund request via our [Contact Form/Support Email], providing your order details and reason for the refund.</li>
                                    <li>Our support team will review your request within 5-7 business days.</li>
                                    <li>Approved refunds will be processed to the original payment method within 7-10 business days.</li>
                                </ul>
                            </p>
                            <br />
                            <h5>Free Trial Period</h5><br>
                            <p>
                                We encourage you to take advantage of our free trial period (if offered) to evaluate the software before making a purchase. Refunds are not provided for users who fail to utilize the trial period.
                            </p>
                            <br />
                            <h5>6. Changes to the Refund Policy</h5><br>
                            <p>
                                We reserve the right to modify this refund policy at any time. Changes will be effective immediately upon posting on our website. If you have any questions regarding this refund policy, please contact our support team at support@roftr.com .
                            </p>
                            <br /> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
   
    <get-started/>
    <footer-home-two class="appie-footer-area appie-footer-about-area"/>
    
    <div class="back-to-top back-to-top-3">
        <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>
 
<style>
ul.custom_bullet {
  list-style-type: disc;
  list-style-position: inside;
}
</style>
<script>

import SidebarHomeTwo from '../Sidebar/SidebarHomeTwo.vue'
import FooterHomeTwo from '../Footer/FooterHomeTwo.vue'
import HeaderProduct from '../Header/HeaderProduct.vue'
import TandATitleArea from './TandATitleArea.vue'
import GetStarted from '../Footer/GetStarted.vue'
import BodyNavSidebar from './BodyNavSidebar.vue';

export default {
	components: { SidebarHomeTwo, TandATitleArea, GetStarted, HeaderProduct, FooterHomeTwo, BodyNavSidebar },
        data(){
        return{
            sidebar:false,
            
            navs:[
                {
                    name:'home',                    
                    path:'/',                    
                },
                {
                    name:'About us',
                    path:'/about-us'            
                },
                {
                    name:'Zoho',
                    path:'/zoho'            
                },
                {
                    name:'Industries',
                        childrens:[
                        {
                            name:'Retail and E-Commerce',
                            path:'/product/e-commerce'
                        },
                        {
                            name:'Financial Services',
                            path:'/product/financial-services'
                        },
                        {
                            name:'Online Movie Ticket Booking',
                            path:'/product/online-ticket-booking'
                        },
                        /*{ 
                            name:'News Media',
                            path:''
                        },
                        { 
                            name:'Static Website',
                            path:''
                        },
                        { 
                            name:'Custom Web Application',
                            path:''
                        },
                        { 
                            name:'Travel and Hospitality',
                            path:''
                        },
                        { 
                            name:'Real Estate / Property Builders',
                            path:''
                        },
                        { 
                            name:'Hospitals and Clinics',
                            path:''
                        },*/
                    ],
                },
                // {
                //     name:'services',
                //     path:'/services'
                // },
                // {
                //     name:'Pricing',
                //     path:'/pricing'
                // },
                {
                    name:'Career',
                    path:'/career'
                },
                {
                    name:'contact',
                    path:'/contact-us'
                }
            ],
        }
    },
   mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },

    methods:{
        topToBottom(){
        const result = document.querySelector('.back-to-top')
           if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight ) {
                result.style.display = "block";
            } else {
                result.style.display = "none";
            }
        },
        toggleSidebar(){
           this.sidebar = !this.sidebar
        },   
    }

}
</script>