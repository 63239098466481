import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import Home from '../views/Home.vue'
import Contact from '../views/Contact.vue'
//import ServiceDetails from '../views/ServiceDetails'
import ECommerce from '../views/E-Commerce.vue' 
import Financialservices from '../views/Financial-services.vue'
import MovieTickets from '../views/Movie-tickets.vue'
import AboutUs from '../views/About.vue'
import Career from '../views/Career.vue'
import CareerForm from '../views/CareersForm.vue'
import Error from '../views/Error.vue'
// import Services from '../views/Services.vue'
import Zoho from '../views/Zoho.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermsAndConditions from '../views/TermsAndConditions.vue'
import RefundPolicy from '../views/RefundPolicy.vue'
import CancellationPolicy from '../views/CancellationPolicy.vue'
import Disclaimer from '../views/Disclaimer.vue'
import EmailPlanPrice from '../views/EmailPlanPrice.vue'
import Staticwebsite from '../views/Static-website.vue'
import PaymentSuccess from '../views/PaymentSuccess.vue'
import PaymentFailure from '../views/PaymentFailure.vue'
import UserAborted from '../views/UserAborted.vue'
import Pricing from '../views/Pricing.vue'



Vue.use(VueRouter)
Vue.use(VueMeta,{ refreshOnceOnNavigation: true})

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about-us',
    name: 'About Us',
    component: AboutUs
  },
  // {
  //   path: '/services',
  //   name: 'Services',
  //   component: Services
  // },
  {
    path: '/services',
    name: 'Services',
    component: Home
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing
  },
  {
   path: '/zoho',
   name: 'Zoho',
   component: Zoho
  },
  {
    path: '/product/e-commerce',
    name: 'Retail and E-Commerce',
    component: ECommerce
  },
  {
    path: '/product/financial-services',
    name: 'Financial Services',
    component: Financialservices
  },
  {
    path: '/product/online-ticket-booking',
    name: 'Online Movie Ticket Booking',
    component: MovieTickets
  },
  {
    path: '/product/static-website',
    name: 'Static Website',
    component: Staticwebsite
  },
  {
    path: '/career',
    name: 'Career',
    component: Career
  },
  {
    path: '/career/:job_id',
    name: 'Jobs',
    component: CareerForm
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: Contact
  },
   {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions
  },
  {
    path: '/refund-policy',
    name: 'RefundPolicy',
    component: RefundPolicy
  },
  {
    path: '/cancellation-policy',
    name: 'CancellationPolicy',
    component: CancellationPolicy
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: Disclaimer
  },
  {
    path: '/payment-success',
    name: 'PaymentSuccess',
    component: PaymentSuccess
  },
  {
    path: '/payment-failure',
    name: 'PaymentFailure',
    component: PaymentFailure
  },
  {
    path: '/user-aborted',
    name: 'UserAborted',
    component: UserAborted
  },
  {
    path: '/services/email-plan-price',
    name: 'EmailPlanPrice',
    component: EmailPlanPrice
  },
  {
    path: '*',
    name: 'Error',
    component: Error
  }
  // { 
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // stop stop cache
  scrollBehavior() {
      document.getElementById('app').scrollIntoView();
  }
})

export default router
