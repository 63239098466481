<template>
    <CancellationPolicy/>
</template>

<script>
import CancellationPolicy from '../components/PolicyPages/CancellationPolicy.vue'
export default {
	components: {CancellationPolicy },
    metaInfo: {
        title: 'Cancellation Policy',
        titleTemplate: '%s | Roftr Software development company, Coimbatore',
        htmlAttrs: {
            lang: 'en-in'
        },
        meta: [
            { name: 'Description', content: 'Cancellation Policy, Billing/Credit Card charging, Copyrights and Trade marks, Abuse Through Content, Service and Product Rates.' },
            { name: 'Author', content: 'Rasu'},
            { name: 'Copyright', content: 'Roftr'},
            { name: 'Classification', content: 'Website Design'},
            { name: 'ROBOTS', content: 'INDEX,FOLLOW'},
            { name: 'DC.title', content: 'Roftr'},
            { name: 'geo.region', content: 'IN-TN'},
            { name: 'geo.placename', content: 'Coimbatore'},
            { name: 'geo.position', content: '11.022291;77.006873'},
            { name: 'ICBM', content: '11.022291, 77.006873'},
        ],
        link:[
            { rel:'alternate', href:'www.roftr.com', hreflang:'en-in'}
        ]
    }
}
</script>

<style>

</style>